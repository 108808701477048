@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';
@import '~@fullcalendar/timegrid/main.css';
@import '~@fullcalendar/bootstrap/main.css';
@import "~bootstrap/scss/bootstrap";

@media screen and (max-width: 640px) {
    .fc-toolbar {
      display:block;
    }
  }